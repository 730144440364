export function parseFilters(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map((item) => parseFilters(item))
  }

  if (typeof obj !== 'object' || obj === null || obj instanceof Date) {
    return obj === 'null' ? null : obj
  }

  const filteredObj: Record<string, any> = {}

  for (const key in obj) {
    let parsedValue = parseFilters(obj[key])

    if (Array.isArray(parsedValue) && parsedValue.length === 0) {
      parsedValue = undefined
    }

    if (
      key === 'missingRequired' &&
      (!obj.missingAnyOf || obj.missingAnyOf.length === 0)
    ) {
      parsedValue = undefined
    }

    if (parsedValue instanceof Date) {
      parsedValue = parsedValue.toISOString()
    }

    if (
      parsedValue !== undefined &&
      parsedValue !== '' &&
      (!isObject(parsedValue) || Object.keys(parsedValue).length > 0)
    ) {
      filteredObj[key] = parsedValue
    }
  }

  return filteredObj
}
